<template>
  <div>
    <el-card>
    <el-page-header @back="goBack" :content="title" style="margin-bottom: 30px;">
    </el-page-header>
        <nav class="input" style="width: 150px">
          <el-button @click="add" type="primary" size="small" :disabled="!($store.state.btn_if.indexOf('cooperation_white_add') != -1)">{{
            '添加授权'
          }}</el-button>
        </nav>
      </nav>
    </el-card>
    <el-card style="margin-top: 10px">
      <el-table
        :data="list"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
      >
        <el-table-column prop="id" label="ID"> </el-table-column>

        <el-table-column prop="name" label="真实姓名" >
         
        </el-table-column>
        <el-table-column prop="createTime"  label="创建时间">
        </el-table-column>
        <el-table-column prop="phone"  label="电话"/>
        <!-- <el-table-column prop="viewNumber"  label="浏览人数"/> -->

        <el-table-column label="操作">
            <template slot-scope="{row}">
              <!-- <el-button
              type="text"
              icon="el-icon-edit"
              size="mini"
              class="skyblue"
              @click="edit(row)"
              v-show="$store.state.btn_if.indexOf('activity_list_edit') != -1"
              >编辑</el-button
            > -->
                <el-button
                type="text"
                icon="el-icon-delete"
                size="mini"
                @click="open(row.id)"
                class="red"
                v-show="$store.state.btn_if.indexOf('cooperation_white_del') != -1"
                >删除</el-button
              >
            </template>
        </el-table-column>
      </el-table>
      <!-- <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="this.search.page"
        background
        :page-sizes="[3, 5, 10, 15]"
        :page-size="this.search.pageSize"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination> -->
    </el-card>
    <Drawers ref="drawer"  :title="'添加授权'"  @getlist="getlist"></Drawers>
   
  </div>
</template>

<script>
let that
// import rules from '../../utils/rules'
import mixins from '@/mixin/list'
export default {
  mixins: [mixins],
  beforeCreate() {
    that = this
  },
  data() {
    return {
      btn: true,
      action: {
        action: 'activity'
      },
      pagesize: 10,
      search: {
        page: 1,
        pageSize: 10,
      },
      s_form: {
        page: 1,
        pageSize: 10,
        sendUser: '',
        receiveUser: '',
        status: '',
        createDate: []
        // order: ''
      },
    }
  },
  updated() {
    //   console.log(this.form);
  },
  components: {
    Drawers:()=>import('@/components/Drawer'),
    Dialog:()=>import('@/components/popup.vue')
  },
  methods: {
    handleSizeChange(e) {
      this.pagesize = e
      console.log(e)
    },

    getlist(val) {
      var form = val ? val : this.search
      this.$http
        .get('/admin/Activity/activityManagersList', {
          params: {
            ...form,
            activityId:this.$route.query.id
          }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.list = res.data.list
            this.title=res.data.activity.title
          } else {
            this.$message.error(res.message)
          }
        })
    },


    
    async add() {
        this.$refs.drawer.drawer=true
        this.$refs.drawer.interface=2
        this.$refs.drawer.activityId = this.$route.query.id
        this.$refs.drawer.getlist()

    },
    edit(row){
        this.$refs.dialogRef.dialogVisible=true
        this.$refs.dialogRef.edit=true
        this.$refs.dialogRef.form={
          name:row.name,
          id:row.id
        }
    },


    open(id) {
      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http
            .post('/admin/Activity/activityManagersDel', { managerId: id,activityId:this.$route.query.id })
            .then(res => {
              if (res.data.errorCode == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
                if (this.total % 10 == 1&&this.total!=1) {

                  this.search.page--
                }
                this.getlist()
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
  },

  created() {
    // this.getconfig()
    // this.getconfig('user')
    this.getlist()
    sessionStorage.setItem('action', 'cooperation')
  },
  mounted() {
    // console.log(rules.req('撒打算'))
    // console.log(this.confing_d)
  },
  computed: {

  }
}
</script>

<style lang="less" scoped>
.out {
  // width: 500px;

  margin-bottom: 20px;
  .input {
    width: 37%;
    display: inline-block;
    margin-right: 30px;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.skyblue {
  color: skyblue;
}
.green {
  color: green;
}
.yellow {
  color: pink;
}
.red {
  color: red;
}
.out .input {
  width: 18%;
}
.el-card {
  position: relative;
}
.out .right {
  float: right;
}
.box {
  display: inline-block;
  margin: 5px;
}
</style>
